import React, { useEffect } from 'react';
import Layout from '../components/shared/layout'
import ProductDetails from '../components/product-details/product-details.component'

export default function ProductInfoPage() {
    useEffect(() => {
        localStorage.setItem('isFromProductInfoPage', true);
    }, [])
    return (
        <Layout header={true} footer={true}>
            <ProductDetails />
        </Layout>
    )
}